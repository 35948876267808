// import default_man_avatar from "./default_man_avatar.jpg";
import ogy_yuce from "./ogy_yuce.jpg";
import ahmet_korkmaz from "./ahmet_korkmaz.jpg";
// import m_ridvan_demir from "./mridvan_demir.jpg";
import muharrem_kilic from "./muharrem_kilic.jpg";

import {
    faFacebookSquare,
    faLinkedin,
    faInstagram,
    faTwitter
} from "@fortawesome/free-brands-svg-icons";

export const dentistImages = [
    {
        image: ogy_yuce,
        alt: "Oğuz Yüce",
        fullName: "Oğuz YÜCE",
        social: [
            {
                title: "linkedIn",
                icon: faLinkedin,
                url: ""
            },
            {
                title: "facebook",
                icon: faFacebookSquare,
                url: ""
            },
            {
                title: "instagram",
                icon: faInstagram,
                url: "https://www.instagram.com/ogzyce/"
            },
            {
                title: "twitter",
                icon: faTwitter,
                url: ""
            }
        ],
        title: "Ağız, Diş ve Çene Cerrahisi Uzmanı"
    },
    {
        image: ahmet_korkmaz,
        alt: "Ahmet KORKMAZ",
        fullName: "Ahmet KORKMAZ",
        social: [
            {
                title: "linkedIn",
                icon: faLinkedin,
                url: ""
            },
            {
                title: "facebook",
                icon: faFacebookSquare,
                url: ""
            },
            {
                title: "instagram",
                icon: faInstagram,
                url: ""
            },
            {
                title: "twitter",
                icon: faTwitter,
                url: ""
            }
        ],
        title: "Endodonti Uzmanı"
    },
    // {
    //     image: m_ridvan_demir,
    //     alt: "M. Rıdvan DEMİR",
    //     fullName: "M. Rıdvan DEMİR",
    //     social: [
    //         {
    //             title: "linkedIn",
    //             icon: faLinkedin,
    //             url: ""
    //         },
    //         {
    //             title: "facebook",
    //             icon: faFacebookSquare,
    //             url: ""
    //         },
    //         {
    //             title: "instagram",
    //             icon: faInstagram,
    //             url: ""
    //         },
    //         {
    //             title: "twitter",
    //             icon: faTwitter,
    //             url: ""
    //         }
    //     ],
    //     title: "Ortodonti Uzmanı"
    // },
    {
        image: muharrem_kilic,
        alt: "dentist3",
        fullName: "Muharrem KILIÇ",
        social: [
            {
                title: "linkedIn",
                icon: faLinkedin,
                url: ""
            },
            {
                title: "facebook",
                icon: faFacebookSquare,
                url: ""
            },
            {
                title: "instagram",
                icon: faInstagram,
                url: ""
            },
            {
                title: "twitter",
                icon: faTwitter,
                url: ""
            }
        ],
        title: "Diş Hekimi"
    },
    // {
    //     image: default_man_avatar,
    //     alt: "Fırat OĞUZ",
    //     fullName: "Fırat OĞUZ",
    //     social: [
    //         {
    //             title: "linkedIn",
    //             icon: faLinkedin,
    //             url: ""
    //         },
    //         {
    //             title: "facebook",
    //             icon: faFacebookSquare,
    //             url: ""
    //         },
    //         {
    //             title: "instagram",
    //             icon: faInstagram,
    //             url: ""
    //         },
    //         {
    //             title: "twitter",
    //             icon: faTwitter,
    //             url: ""
    //         }
    //     ],
    //     title: "Diş Hekimi"
    // }
];