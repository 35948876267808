import {
    faMoon,
    faSun
} from "@fortawesome/free-solid-svg-icons";

export const changeTheme = (setThemeIcon) => {
    document.body.classList.toggle("dark");
    if (document.body.classList.contains("dark")) {
        if (localStorage.getItem("cookiesAccepted") === "true") {
            localStorage.setItem("theme", "dark");
            console.log("true")
        }
        setThemeIcon(faSun);
    }
    else {
        if (localStorage.getItem("cookiesAccepted") === "true") {
            localStorage.removeItem("theme");
            console.log("false")
        }
        setThemeIcon(faMoon);
    }
};