import {
    faHome,
    faAddressCard,
    faStethoscope,
    faTooth,
    // faCalendarCheck,
    faLocationDot,
    faMoon,
    faSun
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Sidebar.css";
import { useEffect, useState } from "react";
import { changeTheme } from "utils/changeTheme";
import { useLocation } from "react-router";

const Sidebar = () => {

    const location = useLocation();

    const [showAside, setShowAside] = useState(false);
    const [topbarHeight, setTopbarHeight] = useState(0);
    const [navbarHeight, setNavbarHeight] = useState(0);
    const [carouselHeight, setCarouselHeight] = useState(0);

    const [themeIcon, setThemeIcon] = useState(faMoon);

    document.addEventListener("scroll", () => {
        const scrollY = window.scrollY;

        if (topbarHeight > 0 && navbarHeight > 0) {
            const totalHeight = topbarHeight + navbarHeight + carouselHeight;
            if (scrollY > totalHeight) {
                setShowAside(true);
            }
            else {
                setShowAside(false);
            }
        }
    });

    useEffect(() => {
        const topbar = document.querySelector("#topbar");
        const navbar = document.querySelector(".navbar");
        const carousel = document.querySelector(".carousel");
        setTopbarHeight(topbar.clientHeight);
        setNavbarHeight(navbar.clientHeight);
        setCarouselHeight(carousel ? carousel.clientHeight : 0);

        const theme = window.localStorage.getItem("theme");
        if (theme && theme !== null && theme !== "" && theme === "dark") {
            document.body.classList.add("dark");
            setThemeIcon(faSun);
        }
    }, []);

    return (
        <aside id="sidebar" className={!showAside ? "hide" : ""}>
            <ul>
                <li>
                    <a href="#topbar">
                        <FontAwesomeIcon icon={faHome} />
                    </a>
                </li>
                {
                    location?.pathname === "/" ?
                        (
                            <>
                                <li>
                                    <a href="#aboutContainer">
                                        <FontAwesomeIcon icon={faAddressCard} />
                                    </a>
                                </li>
                                <li>
                                    <a href="#treatmentsContainer">
                                        <FontAwesomeIcon icon={faStethoscope} />
                                    </a>
                                </li>
                                <li>
                                    <a href="#dentistsContainer">
                                        <FontAwesomeIcon icon={faTooth} />
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="#appointment">
                                        <FontAwesomeIcon icon={faCalendarCheck} />
                                    </a>
                                </li> */}
                                <li>
                                    <a href="#googleMap">
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </a>
                                </li>
                            </>
                        ) : null
                }

                <li>
                    <a href="#changeTheme" role="button" onClick={e => { e.preventDefault(); changeTheme(setThemeIcon) }}>
                        <FontAwesomeIcon icon={themeIcon} />
                    </a>
                </li>
            </ul>
        </aside>
    )
}

export default Sidebar  