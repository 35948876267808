import React from 'react'
import "./Treatments.css";
import { treatmentImages } from 'assets/images/treatments';
import TreatmentCard from './TreatmentCard';

const Treatments = () => {

    return (
        <section id="treatmentsContainer">
            <div id='treatments'>
                <h2 id="treatments-title">Tedavilerimiz</h2>
                <div className="treatmentCards">
                    {treatmentImages.map((treatmentImage, index) => (
                        <TreatmentCard
                            key={`treatment_${index}`}
                            image = {treatmentImage.image}
                            alt = {treatmentImage.alt}
                            title = {treatmentImage.title}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Treatments