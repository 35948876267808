import React from 'react'
import "./Footer.css";
import { images } from "../../assets/images";
import { faEnvelopeOpen, faPhoneAlt, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Social from 'components/Social/Social';
import { companyInfo } from 'data/companyInfo';
import Navbar from 'react-bootstrap/Navbar';
import BottomBar from 'components/BottomBar/BottomBar';
import { Link } from 'react-router';

const Footer = () => {
    return (
        <footer id = "footer">
            <div className="footerContainer">
                <div className="footerLeft">
                    <Navbar.Brand href="#home">
                        <img className='logo' src={images.logo_with_text} alt="" />
                    </Navbar.Brand>
                </div>
                <div className="footerCenter">
                    <h5>Hızlı Linkler</h5>
                    <ul>
                        <li><Link to="/"><FontAwesomeIcon icon={faAnglesRight}/> Anasayfa</Link></li>
                        <li><Link to="/#aboutContainer"><FontAwesomeIcon icon={faAnglesRight}/> Hakkımızda</Link></li>
                        <li><Link to="/#treatmentsContainer"><FontAwesomeIcon icon={faAnglesRight}/> Tedavilerimiz</Link></li>
                        <li><Link to="/#dentistsContainer"><FontAwesomeIcon icon={faAnglesRight}/> Diş Hekimlerimiz</Link></li>
                        <li><Link to="/#footer"><FontAwesomeIcon icon={faAnglesRight}/> İletişim</Link></li>
                        {/* <li><a href="#appointment"><FontAwesomeIcon icon={faAnglesRight}/> Randevu Al</a></li> */}
                        <li><Link to="/gizlilik-politikasi"><FontAwesomeIcon icon={faAnglesRight}/> Gizlilik Politikası</Link></li>
                    </ul>
                </div>
                <div className="footerRight">
                    <h5>Bize Ulaşın</h5>
                    <ul className='contactUs'>
                        <li><FontAwesomeIcon icon={faEnvelopeOpen} /> <span>E-Posta:</span> <a href="mailto:destek@vadidiserzurum.com">destek@vadidiserzurum.com</a></li>
                        <li><FontAwesomeIcon icon={faPhoneAlt} /> <span>Telefon:</span> 0 (544) 134 08 36</li>
                        <li><span>Adres: </span> Adnan Menderes Mah. Rabia Sokak Yazıcılar İnşaat A / Blok No: 8-A <br /> Palandöken / <h1>Erzurum</h1></li>
                    </ul>
                    <br />
                    <h5>Sosyal Medya</h5>
                    <ul className='footerSocial'>
                        {companyInfo.social.map((sc, index) => (
                            <li key={`company_social_${index}`}>
                                <Social
                                    url={sc.url}
                                    title={sc.title}
                                    icon={sc.icon}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <hr />
            <BottomBar />
        </footer>
    )
}

export default Footer