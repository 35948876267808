import { Routes, Route } from "react-router";
import Home from "pages/Home/Home";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import MainLayout from "layouts/MainLayout/MainLayout";
import Wrapper from "utils/Wrapper";
import NotFound from "pages/NotFound/NotFound";


function App() {

    const debug = true;

    if (!debug){
        document.addEventListener("contextmenu", event => {
            event.preventDefault();
        });
        document.addEventListener("keydown", event => {
            if(event.key === "F12"){
                event.preventDefault();
            }
            else if(event.ctrlKey && event.shiftKey && event.code === "KeyI"){
                event.preventDefault();
            }
        });
    }

	return (
        <Wrapper>
            <Routes>
                <Route element={<MainLayout />} > 
                    <Route index element={<Home />} />
                    <Route path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
                    <Route path = "*" element= {<NotFound />}/>
                </Route>
            </Routes>
        </Wrapper>
	);
}

export default App;