import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth } from "@fortawesome/free-solid-svg-icons";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    return (
        <section id="privacy-policy">
            <h2>Gizlilik Politikası</h2>
            <p className='last-update'>Son güncellenme: 15/03/2025</p>
            <p>
                Güvenliğiniz bizim için önemli. Bu sebeple bizimle paylaşacağınız kişisel verileriz hassasiyetle korunmaktadır.
            </p>
            <p>
                Biz, Vadi Erzurum Ağız ve Diş Sağlığı Polikliniği, veri sorumlusu olarak, bu gizlilik ve kişisel verilerin korunması politikası ile, hangi kişisel verilerinizin hangi amaçla işleneceği, işlenen verilerin kimlerle ve neden paylaşılabileceği, veri işleme yöntemimiz ve hukuki sebeplerimiz ile; işlenen verilerinize ilişkin haklarınızın neler olduğu hususunda sizleri aydınlatmayı amaçlıyoruz.
            </p>
            <h3>Veri Toplama ve Kullanım</h3>
            <p>
                Web sitemizde, kullanıcı deneyiminizi iyileştirmek amacıyla tema tercihlerinizi (açık veya koyu mod) cihazınızın yerel depolama alanında (localStorage) saklarız. Bu veri, yalnızca sizin tercihlerinizi hatırlamak için kullanılır ve kişisel olarak tanımlanabilir bir bilgi içermez.
            </p>
            <h3>Veri Güvenliği</h3>
            <p>
                Sakladığımız veriler, yalnızca tarayıcınızda kalır ve sunucularımıza veya herhangi bir üçüncü tarafa aktarılmaz. Bu nedenle, verileriniz tamamen sizin cihazınızda güvende kalır.
            </p>
            <h3>Veri Paylaşımı</h3>
            <p>
                Tema tercihleriniz gibi anonim veriler, hiçbir şekilde üçüncü taraflarla paylaşılmaz veya başka amaçlarla kullanılmaz.
            </p>
            <h3>Çerezler ve Yerel Depolama</h3>
            <p>
                Web sitemiz, temel işlevselliği sağlamak ve kullanıcı tercihlerinizi hatırlamak için çerezler (cookies) veya yerel depolama (localStorage) kullanabilir. Bu teknolojiler, kişisel olarak tanımlanabilir bilgiler içermez ve yalnızca sizin deneyiminizi iyileştirmek amacıyla kullanılır.
            </p>
            <h3>Haklarınız</h3>
            <p>
                <a href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=6698&MevzuatTur=1&MevzuatTertip=5">KVKK</a> madde 11 uyarınca herkes, veri sorumlusuna başvurarak aşağıdaki haklarını kullanabilir:
            </p>
            <ul>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> Kişisel veri işlenip işlenmediğini öğrenme,
                </li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                </li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                </li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
                </li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,
                </li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> Kişisel verilerin silinmesini veya yok edilmesini isteme,
                </li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                </li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkma
                sına itiraz etme,</li>
                <li>
                    <FontAwesomeIcon icon={faTooth} /> Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme, haklarına sahiptir.
                </li>
            </ul>
            <p>Yukarıda sayılan haklarınızı kullanmak üzere destek@vadidiserzurum.com üzerinden bizimle iletişime geçebilirsiniz.</p>
            <h3>Değişiklikler</h3>
            <p>
                Gizlilik politikamızda zaman zaman güncellemeler yapabiliriz. Bu değişiklikler, web sitemizde yayınlandığı anda geçerli olacaktır.
            </p>
            <h3>İletişim</h3>
            <p>
                Daha ayrıntılı bilgi için bizimle <a href="mailto:destek@vadidiserzurum.com">destek@vadidiserzurum.com</a> e-mail adresi üzerinden iletişime geçmekten lütfen çekinmeyiniz.
            </p>
        </section>
    )
}

export default PrivacyPolicy