import CCarousel from "../../components/Carousel/CCarousel";
import About from "../../components/About/About";
import Treatments from "../../components/Treatments/Treatments";
import Dentists from "../../components/Dentists/Dentists";
// import Appointment from../ ."components/Appointment/Appointment";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import { useLocation } from "react-router";
import { useEffect } from "react";

const Home = () => {
    
    const location = useLocation();
    
    const scrollToElement = id => {
        const element = document.querySelector(id);
        if(id.includes("footer")){
            if (element !== null) {
                element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            }
        }
        else{
            if (element !== null) {
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }
    }

    useEffect(() => {
        if(location.hash !== ""){
            scrollToElement(location.hash);
        }
    }, [location]);
    

    return (
        <>
            {/* <Topbar /> */}
            {/* <NNavbar /> */}
            <CCarousel />
            <About />
            <Treatments />
            <Dentists />
            {/* <Appointment/> */}
            <GoogleMap />
            {/* <Footer /> */}
            {/* <PrivacyPolicy/> */}
        </>
    )
}

export default Home