import "./NotFound.css";

const NotFound = () => {
  return (
    <section id='not-found'>
        <h3>404</h3>
        <p>
            Sayfa Bulunamadı!
        </p>
    </section>
  )
}

export default NotFound