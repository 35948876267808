import React, { useRef } from 'react'
import { Link } from 'react-router'
import "./PPolicyBanner.css";

const PPolicyBanner = () => {

    const cookieBanner = useRef(null);

    const acceptCookies = () => {
        localStorage.setItem("cookiesAccepted", "true");
        cookieBanner.current.style.display = "none";
    };

    const rejectCookies = () => {
        localStorage.setItem("cookiesAccepted", "false");
        cookieBanner.current.style.display = "none";
    };


  return (
    <div ref={cookieBanner} className="cookie-banner">
        <p>
            🎨 Web sitemizde, kullanıcı deneyiminizi kişiselleştirmek için çerezler ve yerel depolama kullanıyoruz. Daha fazla bilgi için lütfen <Link to={"/gizlilik-politikasi"}>Gizlilik Politikamızı</Link> okuyun.
        </p>
        <button onClick={() => acceptCookies()}>Kabul Et</button>
        <button onClick={() => rejectCookies()}>Reddet</button>
    </div>
  )
}

export default PPolicyBanner