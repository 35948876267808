import { Outlet } from "react-router";
import NNavbar from "../../components/Navbar/NNavbar";
import Topbar from "../../components/TopBar/Topbar";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import PPolicyBanner from "pages/PrivacyPolicy/PPolicyBanner";

const MainLayout = () => {

    return (
        <>
            <Topbar />
            <NNavbar />
            <Outlet />
            <Footer />
            <Sidebar />
            {
                localStorage.getItem("cookiesAccepted") !== "true" && <PPolicyBanner />
            }
        </>
    )
}

export default MainLayout;