import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Topbar.css";
import { faTooth, faEnvelopeOpen, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const Topbar = () => {
    return (
        <div id="topbar">
            <div id="topbar-left">
                <FontAwesomeIcon icon={faTooth} className="icons"/> <h1>Vadi Erzurum Ağız ve Diş Sağlığı Polikliniği</h1>
            </div>
            <div id="topbar-right">
                <div className="px-4 border-end">
                    <p className="m-0">
                        <FontAwesomeIcon icon={faEnvelopeOpen} /> <a href="mailto:destek@vadidiserzurum.com">destek@vadidiserzurum.com</a>
                    </p>
                </div>
                <div className="px-4">
                    <p className="m-0">
                        <FontAwesomeIcon icon={faPhoneAlt} /> 0 (544) 134 08 36</p>
                </div>
            </div>
        </div>
    )
}

export default Topbar;